<template>
  <div>
    <CodeGenerator
      :is-visible="isVisible"
      :form-data="formData"
      :is-edit-voucher="isEditVoucher"
      @fetchVouchers="fetchVouchers"
      @visible="showVoucherForm"
      @setIsEditVoucher="setIsEditVoucher"
    />
    <my-table
      title="List of Generated Vouchers"
      :rows="rows"
      :columns="columns"
      :pagination="pagination"
      :is-loading="isLoading"
    >
      <template
        v-slot="{props}"
      >
        <span v-if="props.column.field === 'action'">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>
            <b-dropdown-item @click.prevent="assignVoucher(props)">
              <feather-icon
                icon="UserPlusIcon"
                class="mr-50"
              />
              <span>Assign</span>
            </b-dropdown-item>
            <b-dropdown-item @click.prevent="editVoucher(props)">
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="!props.row.hasOrders"
              @click.prevent="deleteVoucher(props)"
            >
              <feather-icon
                icon="TrashIcon"
                class="mr-50"
              />
              <span>Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </span>
      </template>
    </my-table>
    <b-modal
      id="modal-assign-voucher"
      v-model="isShowAssignUsersModal"
      size="lg"
      hide-footer
      centered
      no-close-on-backdrop
      title="Assign Voucher"
      cancel-variant="outline-secondary"
      @hidden="resetAssignVoucherModal"
    >
      <b-form @submit.prevent="assignUsers">
        <b-form-group
          label="Voucher Code"
          label-for="voucher-code"
        >
          <b-form-input
            id="voucher-code"
            v-model="selectedVoucher.code_name"
            readonly
          />
        </b-form-group>
        <b-form-group
          label="Select Users"
          label-for="vue-select"
        >
          <v-select
            v-model="selectedUsers"
            multiple
            label="company_name"
            :options="b2bProUsers"
            :reduce="company => company.id"
          />
          <small class="text-danger">{{ assignUserError.length > 0 ? assignUserError[0][0] : '' }}</small>
        </b-form-group>
        <b-form-group>
          <b-button
            variant="success"
            class="float-right"
            type="submit"
            :disabled="addUsersLoading"
          >
            <b-spinner
              v-if="addUsersLoading"
              class="mr-50"
              small
            />
            <feather-icon
              v-else
              icon="SaveIcon"
              class="mr-50"
            />
            <span class="align-middle">Submit</span>
          </b-button>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal, BForm, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { CodeGenerator, MyTable } from '@/components'
import axios from '@axios'

export default {
  name: 'VouchersGenerator',
  components: {
    CodeGenerator,
    MyTable,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      isLoading: false,
      addUsersLoading: false,
      columns: [
        {
          label: 'Date Created',
          field: 'date_added',
        },
        {
          label: 'Codes',
          field: 'code_name',
        },
        {
          label: 'Validity',
          field: 'validity',
        },
        {
          label: 'Total Used Count',
          field: 'total_used_count',
        },
        {
          label: 'Generated By',
          field: 'generated_by',
        },
        {
          label: 'Usage Limit',
          field: 'max_usage_text',
        },
        {
          label: 'Amount',
          field: 'amount',
        },
        {
          label: 'Type',
          field: 'type',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      pagination: {
        enabled: true,
        mode: 'records',
        perPage: 5,
        perPageDropdown: [5, 10, 15],
      },
      rows: [],
      isVisible: false,
      isEditVoucher: false,
      formData: {},
      b2bProUsers: [
        {
          id: 1,
          company_name: 'Jerry',
        },
        {
          id: 2,
          company_name: 'Jerry Corp',
        },
      ],
      selectedUsers: [],
      isShowAssignUsersModal: false,
      selectedVoucher: {
        code_name: '',
      },
      assignUserError: [],
    }
  },
  created() {
    this.fetchVouchers()
    this.fetchB2bProUsers()
  },
  methods: {
    async fetchB2bProUsers() {
      const response = await axios.get('/b2bpro-users?for_select_field=true')
      if (response.status === 200) {
        this.b2bProUsers = response.data.data
      }
    },
    async fetchVouchers() {
      this.isLoading = true
      const response = await axios.get('/admin/vouchers')
      if (response.status === 200) {
        this.rows = response.data.data
      }
      this.isLoading = false
    },
    async deleteVoucher(action) {
      this.$swal({
        title: 'Delete Voucher',
        text: 'Are you sure to delete this voucher?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await axios.delete(`/admin/vouchers/${action.row.id}`)
          if (response.status === 200) {
            this.rows.splice(action.index, 1)
            this.$swal({
              icon: 'success',
              title: 'Deleted!',
              text: 'Successfully Deleted',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        }
      })
    },
    editVoucher(row) {
      this.showVoucherForm(true)
      this.setIsEditVoucher(true)
      this.formData = row.row
    },
    async assignUsers() {
      this.addUsersLoading = true
      const response = await axios.post(`/admin/assign-vouchers/${this.selectedVoucher.id}`, { users: this.selectedUsers })
      if (response.status === 422) {
        this.assignUserError = Object.values(response.data.errors)
        this.addUsersLoading = false
        return
      }

      if (response.status === 200) {
        this.resetAssignVoucherModal()
        this.isShowAssignUsersModal = false
        this.addUsersLoading = false
        this.$swal({
          icon: 'success',
          title: 'Assigned',
          text: 'Voucher Successfully Assigned To Users',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
      }
    },
    assignVoucher(row) {
      this.selectedVoucher = row.row
      this.isShowAssignUsersModal = true
    },
    showVoucherForm(val = false) {
      this.isVisible = val
    },
    setIsEditVoucher(val = false) {
      this.isEditVoucher = val
    },
    resetAssignVoucherModal() {
      this.selectedUsers = []
      this.assignUserError = []
    },
  },
}
</script>
